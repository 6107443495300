/* MAIN */
.container {
  margin-bottom: 120px;
}

/* FONTS */
@font-face {
  font-family: lineto-circular-pro-book;
  src: url(./fonts/lineto-circular-pro-book.ttf);
}
@font-face {
  font-family: lineto-circular-pro-bold;
  src: url(./fonts/lineto-circular-pro-bold.ttf);
}
@font-face {
  font-family: lineto-circular-black;
  src: url(./fonts/lineto-circular-black.ttf);
}

/* PRINCIPAL */
h1,
h2,
h3 {
  color: white;
  font-family: lineto-circular-black, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}

div,
h6,
h5,
p {
  color: white;
  font-family: lineto-circular-pro-book, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}

div > p,
div > h5,
div > h6 {
  margin: 0px;
}

/* COLORS */
:root {
  --navbar-black: #040404;
  --background-black: #121212;
  --mini-player-grey: #282828;
  --selected-grey: #2a2a2a;
  --mini-player-controls-off: #535353;
  --under-grey: #969696;
  --mini-player-controls-on: #b3b3b3;
  --green: #1db954;
  --green-hover: #1ed760;
}

/* BACKGROUND */
.navbar-spoti {
  background-color: var(--navbar-black);
}

.form-control {
  margin-bottom: 30px;
}

/* BUTTONS */

.navbar-link {
  color: white;
  text-decoration: none;
  margin-right: 5px;
}
.navbar-link:hover {
  color: white;
  text-decoration: none;
}

button.btn-spoti,
a.btn-spoti {
  font-family: lineto-circular-pro-bold;
  font-size: 20px;
  width: 250px;
  color: white;
  background-color: var(--green);
  border-radius: 30px;
}

button.btn-spoti:hover,
a.btn-spoti:hover {
  color: white;
  background-color: var(--green-hover);
}

button.btn-spoti-clear {
  font-family: lineto-circular-pro-bold;
  color: white;
  border-radius: 30px;
  font-size: 20px;
  width: 220px;
  background-color: rgb(0, 0, 0, 0);
  border: 2px solid white;
}

button.btn-spoti-clear:hover {
  color: white;
  background-color: rgb(0, 0, 0, 1);
}

h3 > a,
p > a {
  color: var(--green-hover);
}

h3 > a:hover,
p > a:hover {
  color: var(--green-hover);
}

div.track-info > div > h5 {
  color: var(--under-grey);
}

/* RESULTS */

.big-result:hover {
  cursor: pointer;
  background-color: var(--selected-grey);
}

.big-result > p {
  font-family: lineto-circular-pro-bold;
  font-size: 14px;
}

.big-results-title,
.small-results-title,
.tracks-title {
  border-bottom: solid 1px white;
}

.small-results {
  color: white;
  padding: 10px;
  margin-bottom: 10px;
}

.small-result:hover,
.track:hover {
  cursor: pointer;
  background-color: var(--selected-grey);
}

.small-result,
.track {
  align-items: center;
}

div.small-result-info > p,
div.mini-player-text > div > h6 > a {
  font-size: 13px;
  color: var(--under-grey);
}

/* MINI-PLAYER */
.green {
  color: var(--green);
}

.mini-player {
  border-top: solid 1px black;
  background-color: var(--mini-player-grey);
  position: fixed;
  bottom: 0;
  width: 100%;
}

.mini-player-volume > div,
.mini-player-controls > div,
.back {
  color: var(--mini-player-controls-on);
}

.back:hover {
  color: white;
}

.mini-player-buttons > i:hover {
  color: white;
}

.mini-player-buttons > i.green:hover {
  color: var(--green-hover);
}

div.mini-player-text > div > h5 > a {
  font-size: 14px;
  color: white;
}

.time-bar > p {
  font-size: 12px;
  color: var(--mini-player-controls-on);
}

#time-bar,
#volume-bar {
  cursor: pointer;
}

/* MOBILE */
#track-name,
#artist-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

p#mobile-text > span {
  color: var(--under-grey);
}

.overflow-container {
  overflow: hidden;
}

.overflow {
  font-family: lineto-circular-pro-bold;
  font-size: 15px;
  white-space: nowrap;
  animation: marquee 16s linear infinite;
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

#mini-player-mobile,
.fa {
  cursor: pointer;
}
